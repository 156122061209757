import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/PostList';
import { Hero, Cta } from '../acf';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { page, site, allWordpressPost, categories } = data;
    const { title: siteTitle } = site.siteMetadata;
    const { edges: posts } = allWordpressPost
    const {
      pageTitle,
      yoast,
      acf
    } = page;
    const { 
      subheading, 
      heading,
      blurb, 
      backgroundImage,
      isCaseStudy
    } = acf.layout[0];

    return (
      <Layout location={location}>
        <SEO title={`Blog | ${siteTitle}`} />
        <Hero subheading={subheading} heading={heading} blurb={blurb} backgroundImage={backgroundImage} />
        <div className="case-studies-list-container">
          <div className="wrapper">
            <PostList
              posts={posts}
              title="Latest posts"
              pageContext={pageContext}
              categories={categories.edges}
              siteMetadata={site.siteMetadata}
              pathPrefix={`/${site.siteMetadata.blogSlug}/`}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    page: wordpressPage(slug: {eq: "blog"}) {
      yoast {
        metadesc
        title
      }
      pageTitle: title
      acf {
        layout: layout_page {
          __typename,
          ... on WordPressAcf_Hero {
            id
            subheading
            heading
            blurb
            backgroundImage {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90, srcSetBreakpoints: [ 1600 ], grayscale: true) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text,
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title,
        siteUrl,
        wordpressUrl,
        blogSlug
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
